import {
    Button,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Subtitle1,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatHelp24, Dismiss24 } from '../shared/BundledIcons';

const useClasses = makeStyles({
    root: {
        maxWidth: '1052px',
        height: '852px',
        width: 'fit-content',
        display: 'flex',
    },
    title: {
        ...shorthands.margin(0, 0, '12px'),
    },
    description: {
        ...shorthands.margin(0, 0, '12px'),
    },
    dialogContent: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflowY: 'auto',
        rowGap: '24px',
        columnGap: '24px',
        ...shorthands.padding('12px', '2px', '12px'),
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
    },
});

export const Help: React.FC = () => {
    const classes = useClasses();

    const { serviceInfo } = useAppSelector((state: RootState) => state.app);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversations[selectedId], open, serviceInfo.availablePlugins]);

    return (
        <Dialog
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogTrigger>
                <Button
                    data-testid="pluginButton"
                    style={{ color: 'white' }}
                    appearance="transparent"
                    icon={<ChatHelp24 color="white" />}
                    title="Plugins Gallery"
                    aria-label="Plugins Gallery"
                >
                    Help
                </Button>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    data-testid="closeEnableCCPluginsPopUp"
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24 />}
                                />
                            </DialogTrigger>
                        }
                    >
                        <Subtitle1 block className={classes.title}>
                            Turorials and Instructions Coming Soon!
                        </Subtitle1>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        {/* <Subtitle1 block className={classes.title}>
                            December 10th Release
                        </Subtitle1>
                        <Body1 as="p" block className={classes.description}>
                        We are excited to announce some updates and changes to our company chatbot, CAPPY. In this update, we have fixed some issues and added some new features to improve the user experience.
                        </Body1>
                        <Body1 as="p" block className={classes.description}>
                            Fixes:
                            <ul>
                                <li>Fixed the Dark mode feature, which was causing some display issues.</li>
                                <li>We have adjusted the document chunk size for better performance.</li>
                                <li>The maximum file upload limit has been increased to 50 documents per upload.</li>
                            </ul>
                        </Body1>
                        <Body1 as="p" block className={classes.description}>
                            New Changes:
                            <ul>
                                <li>We have made changes to the icons used in CAPPY to improve the overall look and feel of the app.</li>
                                <li>The Whats New icon has been added to keep our users updated on the latest changes and updates.</li>
                                <li>Addition of Cappy Apps, a set of tools and features to enhance productivity and efficiency.</li>
                            </ul>
                        </Body1>
                        <Body1 as="p" block className={classes.description}>
                            Upcoming Features:
                            <ul>
                                <li>We are working on a feature to email chat logs to end-users for better communication and record-keeping.</li>
                                <li>An Excel plugin is in the works to allow users to allow users to synthesize answers from data in Excel.</li>
                                <li>An MS Graph plugin is in the works to allow for better integration with Microsoft products.</li>
                                <li>Domain-specific agents/assistants to better serve our users needs and increase the quality and accuracy of responses.</li>
                                <li>The ability to have multi-agent chat instances to allow for agents to work together in the same instance to answer questions.</li>
                            </ul>
                        </Body1>
                        <Body1 as="p" block className={classes.description}>
                            <b>CAPPY Office Hours: </b>
                            <br />
                            We would like to invite you to join us for CAPPY office hours every Wednesday from 1:00 PM to 2:00 PM EST. Our team will be available to answer any questions you have about CAPPY and its features. Please use this meeting link to join:
                            <br />
                            <a href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_Yzc0M2E1ZjAtMDk1My00YmJiLWJiN2QtY2FiZDFmZGZhOTI3%40thread.v2/0?context=%7b%22Tid%22%3a%225ca06f8c-18e2-418a-b266-dffb227cfc46%22%2c%22Oid%22%3a%2296e5625d-8e1b-4f0e-b53d-cbda23a92ad4%22%7d">
                                Join CAPPY Office Hours
                            </a>
                            
                           
                        </Body1> */}
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
