import {
    Body1,
    Button,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Subtitle1,
    Subtitle2,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import AITranscriptIcon from '../../assets/app-icons/AITranscript.png';
import FinOpsIcon from '../../assets/app-icons/Finops.png';
import InstitutionalIcon from '../../assets/app-icons/Institutional.png';
import MarketingLabelIcon from '../../assets/app-icons/MarketingLabel.png';
import PortfolioIcon from '../../assets/app-icons/Portfolio.png';
import TFRIcon from '../../assets/app-icons/TFR.png';
import WallboardIcon from '../../assets/app-icons/Wallboard.png';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { AppFolder24, Dismiss24 } from '../shared/BundledIcons';
import { BaseCard } from './cards/BaseCard';

const useClasses = makeStyles({
    root: {
        maxWidth: '1052px',
        height: '852px',
        width: 'fit-content',
        display: 'flex',
    },
    title: {
        ...shorthands.margin(0, 0, '12px'),
    },
    description: {
        ...shorthands.margin(0, 0, '12px'),
    },
    dialogContent: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflowY: 'auto',
        rowGap: '24px',
        columnGap: '24px',
        ...shorthands.padding('12px', '2px', '12px'),
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
    },
    card: {
        width: '480px',
        maxWidth: '100%',
        height: '124px',
    },
});

const openPopupFinOps = () => {
    // Opening a new popup window
    const popup = window.open('https://finopsext.captrust.com/', 'Popup', 'width=800,height=1200');

    // Optional: focus on the popup if it's not null
    if (popup) popup.focus();
};

const openPopupPort = () => {
    // Opening a new popup window
    const popup = window.open('https://dazportfolioext.azurewebsites.net/', 'Popup', 'width=800,height=1200');

    // Optional: focus on the popup if it's not null
    if (popup) popup.focus();
};

const openClientLabel = () =>{
    // Opening a new popup window
    const popup = window.open('https://clientlabeler.azurewebsites.net/', 'Popup', 'width=800,height=1200');
    if (popup) popup.focus();
}

export const PluginGallery2: React.FC = () => {
    const classes = useClasses();

    const { serviceInfo } = useAppSelector((state: RootState) => state.app);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversations[selectedId], open, serviceInfo.availablePlugins]);

    return (
        <Dialog
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogTrigger>
                <Button
                    data-testid="pluginButton"
                    style={{ color: 'white' }}
                    appearance="transparent"
                    icon={<AppFolder24 color="white" />}
                    title="Plugins Gallery"
                    aria-label="Plugins Gallery"
                >
                    APPS
                </Button>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    data-testid="closeEnableCCPluginsPopUp"
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24 />}
                                />
                            </DialogTrigger>
                        }
                    >
                        <Subtitle1 block className={classes.title}>
                            CAPPY APPs (Under Testing)
                        </Subtitle1>
                        <Body1 as="p" block className={classes.description}>
                            Cappy apps are a set of AI-powered applications that can be accessed through Cappy. These
                            apps are designed to perform specific tasks.
                        </Body1>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Subtitle2 block className={classes.title}>
                            Availbable Apps
                        </Subtitle2>
                        <div className={classes.content}>
                            <BaseCard
                                image={FinOpsIcon}
                                header={'FinOps Document Extraction'}
                                secondaryText={'N/A'}
                                description={'Extract payout data from third party invoices'}
                                action={
                                    <Button
                                        data-testid="disconnectPluginButton"
                                        aria-label="Disconnect plugin"
                                        appearance="secondary"
                                        onClick={openPopupFinOps}
                                    >
                                        Enter
                                    </Button>
                                }
                            />
                            <BaseCard
                                image={PortfolioIcon}
                                header={'Portfolio Document Extraction'}
                                secondaryText={'N/A'}
                                description={'Extract portfolio data from selected brokerage statements'}
                                action={
                                    <Button
                                        data-testid="disconnectPluginButton"
                                        aria-label="Disconnect plugin"
                                        appearance="secondary"
                                        onClick={openPopupPort}
                                    >
                                        Enter
                                    </Button>
                                }
                            />
                            <BaseCard
                                image={MarketingLabelIcon}
                                header={'Marketing Client Labeler'}
                                secondaryText={'N/A'}
                                description={'Label and download the differences between vendor data and current client data '}
                                action={
                                    <Button
                                        data-testid="disconnectPluginButton"
                                        aria-label="Disconnect plugin"
                                        appearance="secondary"
                                        onClick={openClientLabel}
                                    >
                                        Enter
                                    </Button>
                                }
                            />
                        </div>
                        <Subtitle2 block className={classes.title}>
                            Upcoming Apps
                        </Subtitle2>
                        <div className={classes.content}>
                            <BaseCard
                                image={InstitutionalIcon}
                                header={'Institutional Document Extraction'}
                                secondaryText={'N/A'}
                                description={''}
                                action={
                                    <Button
                                        data-testid="disconnectPluginButton"
                                        aria-label="Disconnect plugin"
                                        appearance="secondary"
                                    >
                                        Coming Soon
                                    </Button>
                                }
                            />
                            <BaseCard
                                image={TFRIcon}
                                header={'Trade Authorization Generation'}
                                secondaryText={'N/A'}
                                description={''}
                                action={
                                    <Button
                                        data-testid="disconnectPluginButton"
                                        aria-label="Disconnect plugin"
                                        appearance="secondary"
                                    >
                                        Coming Soon
                                    </Button>
                                }
                            />
                            <BaseCard
                                image={AITranscriptIcon}
                                header={'AI Transcription Tool'}
                                secondaryText={'N/A'}
                                description={''}
                                action={
                                    <Button
                                        data-testid="disconnectPluginButton"
                                        aria-label="Disconnect plugin"
                                        appearance="secondary"
                                    >
                                        Coming Soon
                                    </Button>
                                }
                            />
                            <BaseCard
                                image={WallboardIcon}
                                header={'Wallboard Queue Tool'}
                                secondaryText={'N/A'}
                                description={''}
                                action={
                                    <Button
                                        data-testid="disconnectPluginButton"
                                        aria-label="Disconnect plugin"
                                        appearance="secondary"
                                    >
                                        Coming Soon
                                    </Button>
                                }
                            />
                        </div>

                        {/* <Card>
                            <p>FinOps Document Extraction3</p>
                            <Button
                                data-testid="disconnectPluginButton"
                                aria-label="Disconnect plugin"
                                appearance="secondary"
                            >
                                <PluginGallery3 />
                            </Button>
                        </Card> */}
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
